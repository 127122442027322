import { useOutletContext } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';

import { Grid, Box, Button, Paper, Alert } from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {Typography} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Api from "../Services/Api";
import { myLog } from "../Services/Functions";

import MainButton from "../Components/MainButton";
import FastAutocomplete from "../Components/FastAutocomplete";
import FormikValidation from "../Components/FormikValidation";

function Dashboard() {
    const me = useOutletContext();
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [displayAlert, setDisplayAlert] = useState(0);
    const [displayValidation, setDisplayValidation] = useState(false);

	const [listCustomersResponse, setListCustomersResponse] = useState(null)
	const [listOrdersResponse, setListOrdersResponse] = useState(null)

	const [listCustomers, setListCustomers] = useState([])
	const [listOrders, setListOrders] = useState([])

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [selectedOrder, setSelectedOrder] = useState(null);   //For future use. Now data is retrieved through Formik

    const [currentOrder, setCurrentOrder] = useState(null); //Used on order selected

    const setCurrentOrderFromBe = async () => {
        try {
            await Api.get({endpoint: 'manage/currentOrder', contentType : 'application/json'})
                .then(response => {
                    setCurrentOrder(response.data.currentOrder);
                    return true;
                })
                .catch(error => {
                    myLog(JSON.stringify(error));
                    return false;
                });
		} catch (error) {
			myLog(JSON.stringify(error));
            return false;
		}
    }

    useEffect(() => {
		try {
            if (setCurrentOrderFromBe()) {
                Api.get({endpoint: 'customers?combo=1', contentType : 'application/json'})
                    .then(response => {
                        setListCustomersResponse(response.data.customers);                        //{ id: 'option1', label: 'Opzione 1' },
                    })
                    .catch(error => {
                        myLog(JSON.stringify(error));
                        setDisplayAlert(-1);
                    });
            }
            else {
                setListCustomersResponse(null)
                setDisplayAlert(-1);
            }
		} catch (error) {
			myLog(JSON.stringify(error));
            setListCustomersResponse(null);
			setDisplayAlert(-1);
		}
    }, []); 	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		try {
			if (!listCustomersResponse) {
				setListCustomers([
					{ id: -1, label: 'Caricamento in corso...' },
				]);
			}
			else {
				setListCustomers(listCustomersResponse);	//Already formatted in controller
			}
		} catch (error) {
			myLog(error);
			return (error.response ? error.response.status : null);
		}
	}, [listCustomersResponse]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		try {
			if (!listOrdersResponse) {
				setListOrders([
					{ id: -1, label: 'Selezionare un cliente...' },
				]);
			}
			else {
				setListOrders(listOrdersResponse);  //Already formatted in controller
			}
		} catch (error) {
			myLog(error);
			return (error.response ? error.response.status : null);
		}
	}, [listOrdersResponse]);	// eslint-disable-line react-hooks/exhaustive-deps

	/*
    useEffect(() => {
		try {

		} catch (error) {
			myLog(error);
			return (error.response ? error.response.status : null);
		}
	}, [selectedInstallation]);	// eslint-disable-line react-hooks/exhaustive-deps
    */

	useEffect(() => {
		try {
			if (selectedCustomer) {
				Api.get({endpoint: `orders/list/${selectedCustomer.id}?combo=1`, contentType : 'application/json'})
					.then(response => {
						setSelectedOrder(null);
						setListOrdersResponse(response.data.orders);
					})
					.catch(error => {
						myLog(error);
						setListOrdersResponse(null);
						setDisplayAlert(-1);
					});
			}
		} catch (error) {
			myLog(error);
			return (error.response ? error.response.status : null);
		}
	}, [selectedCustomer]);	// eslint-disable-line react-hooks/exhaustive-deps

    const initialValues = useMemo(() => ({
        customer_id: null,
        order_id: null,
        _method: 'post',
    }), []);  // nevere recompute

	const formik = useFormik({
		initialValues,
		validationSchema: Yup.object({
			customer_id:
				Yup.number()
				.integer("Valore non accettato")
				.min(-1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
			order_id:
				Yup.number()
				.integer("Valore non accettato")
				.min(-1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
		}),
		onSubmit: values => {
			setLoading(true);
            
			try {
				Api.post({endpoint: `manage/setOrder`, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						//let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);

                        setCurrentOrderFromBe();
                        setLoading(false);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
							}
							else {
								myLog(error);
							}
                            setLoading(false);
							return;
						}
						else {
							myLog(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
                        setLoading(false);
					});
			} catch (error) {
				myLog(error);
				setDisplayAlert(-1);
                setLoading(false);
			}
		},
		enableReinitialize: true,
	});

	const customerSelected = (customer) => {
		if (customer?.id) {	//Read FastAutocomplete.handleOnChange notes
			setListOrdersResponse(null);
            formik.setFieldValue('order_id', null);
			setSelectedCustomer(customer);
		}
		if (customer === null) {
			setSelectedCustomer(null);
		}
	}

	const orderSelected = (order) => {
		if (order?.id) {	//Read FastAutocomplete.handleOnChange notes
			setSelectedOrder(order);
		}
		if (order === null) {
			setSelectedOrder(null);
		}
	}

	const renderSelectBox = () => {
		return (
            <FormikProvider value={formik}>
                <form
                    autoComplete="off"
                    noValidate
                    onSubmit={formik.handleSubmit}
                >
                    <Box
                        width="100%"
                        component={Paper}
                        elevation={3}
                        sx={{
                            padding: 2,
                            marginTop: 2,
                            borderColor: "primary"
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FastAutocomplete
                                    field="customer_id"
                                    formik={formik}
                                    options={listCustomers}
                                    onChange={customerSelected}
                                    loading={loading}
                                    label="Cliente"
                                    required={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FastAutocomplete
                                    field="order_id"
                                    formik={formik}
                                    options={listOrders}
                                    onChange={orderSelected}
                                    loading={loading}
                                    label="Commessa"
                                    required={true}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                     onClick={() => setDisplayValidation(true)}
                                >
                                    Invia
                                </Button>
                            </Grid>
                        </Grid>
                        <FormikValidation formik={formik} displayValidation={displayValidation} />
                    </Box>
                    <FormikValidation formik={formik} tech={true} />
                </form>
            </FormikProvider>
		)
	}
    
    const closeOrder = () => {
        if (!window.confirm("Confermi di voler chiudere il lavoro su questo cantiere?")) {
            return;
        }

        setLoading(true);
        try {
            Api.get({endpoint: 'manage/closeOrder', contentType : 'application/json'})
			.then(response => {
				setCurrentOrder(null);
                setLoading(false);
			})
			.catch(error => {
				myLog(JSON.stringify(error));
                setLoading(false);
				setDisplayAlert(-1);
			});

        } catch (error) {
            myLog(JSON.stringify(error));
            setLoading(false);
            setDisplayAlert(-1);
        }
    }

	return (
		<>
			<h2>{`Ciao, ${me.name}`}</h2>
			<h5>Ecco la tua Dashboard per il controllo delle attività</h5>

            {currentOrder ? (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={15}>
                    <Typography variant="h6" gutterBottom sx={{ mb: 4, textAlign: 'center' }}>
                        Stai lavorando su:<br />
                        {`Commessa: ${currentOrder.order} - ${currentOrder.customer}`}
                        {currentOrder.worksite ? <><br />{`Cantiere: ${currentOrder.worksite}`}</> : null}
                        {currentOrder.description ? <><br />{`Descrizione: ${currentOrder.description}`}</> : null}
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item>
                            <MainButton badge={currentOrder.numPcq ?? "0"} label="PCQ" icon={ChecklistIcon} buttonColor={theme.palette.secondary.dark} loading={loading} href="pcq" />
                        </Grid>
                        <Grid item sx={{ position: 'relative' }}>
                            <MainButton badge={currentOrder.numReports ?? "0"} label="Rapportini" icon={AssignmentIcon} loading={loading} onClick={() => alert("Funzione non attiva")} />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '40px',
                                    left: '70px',
                                    backgroundColor: 'red',
                                    color: 'white',
                                    transform: 'rotate(45deg)',
                                    transformOrigin: 'top left',
                                    width: '200px',
                                    height: '40px',
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    padding: '5px',
                                    zIndex: 2
                                }}
                            >
                                Arriva presto!
                            </Box>
                        </Grid>
                    </Grid>
                    <Typography variant="h6" gutterBottom sx={{ mt: 6, textAlign: 'center' }}>
                        Se vuoi chiudere il lavoro su questo cantiere clicca{' '}
                        <Button variant="contained" onClick={closeOrder} disabled={loading} sx={{ ml: 1 }}>QUI</Button>
                    </Typography>
                </Box>
            ) : (
                renderSelectBox()
            )}

            {displayAlert === -1 && (
                <Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
            )}
		</>
	);
}

export default Dashboard;
