import React, { useState, useEffect } from 'react';

import { isMobile } from 'react-device-detect';

import clsx from 'clsx';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { styled, alpha } from '@mui/material/styles';
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import { TreeItem2Checkbox, TreeItem2Content, TreeItem2IconContainer, TreeItem2Label } from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';

import { PieChart } from '@mui/x-charts/PieChart';

import Api from "../Services/Api";
import { myLog, IconFromString } from "../Services/Functions";
import loadingGif from '../Assets/loading.gif';
import CurrentOrder from '../Components/CurrentOrder';
import PcqCompile from '../Components/Pcq/PcqCompile';

function Pcq() {
    const [currentOrder, setCurrentOrder] = useState(null);
    const [treeViewData, setTreeViewData] = useState(null);
    const [rtwPublicApi, setRtwPublicApi] = useState(null);
    const [crudOpen, setCrudOpen] = useState(false);
    const [crudSelectedId, setCrudSelectedId] = useState(null);
    const [lastUpdate, setLastUpdate] = useState(null);
    
    /***** Tree View service functions *****/
    const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({        //Set style for elements in various conditions (hover, selected, etc..)
        flexDirection: 'row-reverse',
        borderRadius: theme.spacing(0.7),
        marginBottom: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
        padding: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        [`&.Mui-expanded `]: {
            '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
                color: theme.palette.primary.dark,
                ...theme.applyStyles('light', {
                    color: theme.palette.primary.main,
                }),
            },
            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: '16px',
                top: '44px',
                height: 'calc(100% - 48px)',
                width: '1.5px',
                backgroundColor: theme.palette.grey[700],
                ...theme.applyStyles('light', {
                    backgroundColor: theme.palette.grey[300],
                }),
            },
        },
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            color: 'white',
            ...theme.applyStyles('light', {
                color: theme.palette.primary.main,
            }),
        },
        [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            ...theme.applyStyles('light', {
                backgroundColor: theme.palette.primary.main,
            }),
        },
    }));

    function TransitionComponent(props) {       //Transition function (soft expand)
        return <Collapse in={props.in} {...props} />;
    }

    const StyledTreeItemLabelText = styled(Typography)({    //Set style for label (can be removed or enriched)
        color: 'inherit',
    });

    function CustomLabel({ icon: Icon, expandable, children, color, ...other }) {  //Set structure for label
        return (
            <TreeItem2Label
                {...other}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: isMobile ? '20px' : 'inherit',
                }}
            >
                {Icon && (
                    <Box
                        component={Icon}
                        className="labelIcon"
                        sx={{ mr: 1, fontSize: '1.2rem', color: `${color} !important` }}
                    />
                )}

                <StyledTreeItemLabelText style={{'fontSize': isMobile ? '1.3em' : 'inherit'}} variant="body2">{children}</StyledTreeItemLabelText>
            </TreeItem2Label>
        );
    }

    const isExpandable = (reactChildren) => {   //Detect if element is expandable
        if (Array.isArray(reactChildren)) {
            return reactChildren.length > 0 && reactChildren.some(isExpandable);
        }
            return Boolean(reactChildren);
    };

    var statusIconMapping = {
        '1' : { //Da eseguire
            'icon': 'PanTool',
        },
        '2' : { //In corso
            'icon': 'Engineering',
        },
        '3' : { //Verificato e chiuso
            'icon': 'VerifiedUser',
        },
    };

    const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {   //Main customization function
        const { id, itemId, label, disabled, children } = props;

        const {
            getContentProps,
            getIconContainerProps,
            getCheckboxProps,
            getLabelProps,
            getGroupTransitionProps,
            getDragAndDropOverlayProps,
            status,
            publicAPI,
        } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });        //Headless API

        setRtwPublicApi(publicAPI);
        const item = publicAPI.getItem(itemId);       //Retrieve source element (with all it's attributes)

        //Set icon > readed from b.e on also on level 1 (expandable)
        const expandable = isExpandable(children);
        let icon;
        let color;
        if (!item.status) {
            icon = IconFromString("FolderRounded");
            color = 'inherit';
        }
        else {
            icon = IconFromString(statusIconMapping[item.status].icon);
            color = item.color;
        }

        return (
            <TreeItem2Provider itemId={itemId}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            'Mui-expanded': status.expanded,
                            'Mui-selected': status.selected,
                            'Mui-focused': status.focused,
                            'Mui-disabled': status.disabled,
                        }),
                    })}
                >
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <TreeItem2Checkbox {...getCheckboxProps()} />
                    <CustomLabel
                        {...getLabelProps({ icon, color, expandable: expandable && status.expanded })}
                    />
                    <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
                </CustomTreeItemContent>
                {children && <TransitionComponent {...getGroupTransitionProps()} />}

            </TreeItem2Provider>
        );
    });

    /***** Graph service functions *****/
    function calculateDiagramData() {
        const statusCounts = {};
        const statusDescriptions = [];
        let totalCount = 0;

        function addStatusDescription(status, description, color) {
            const exists = statusDescriptions.some(item => item.status === status);
            if (!exists) {
                statusDescriptions.push({ status, description, color });
            }
        }

        function processChildren(children) {
            children.forEach(child => {
                if (!child.children) {
                    const status = child.status;
                    const description = child.description;
                    const color = child.color;

                    addStatusDescription(status, description, color);

                    if (statusCounts[status]) {
                        statusCounts[status]++;
                    } else {
                        statusCounts[status] = 1;
                    }
                    totalCount++;
                }
            });
        }

        if (treeViewData) {
            treeViewData.forEach(item => {
                if (item.children) {
                    processChildren(item.children);
                }
            });
        }

        const statusPercentages = Object.keys(statusCounts).map((status, index) => {
            const value = (statusCounts[status] / totalCount) * 100;
            const statusData = statusDescriptions.find(item => item.status === parseInt(status));
            const description = statusData.description;
            const color = statusData.color;

            return {
                id: index,
                value: value.toFixed(2),
                label: `${description} (${value.toFixed(2)}%)`, // Usa la descrizione trovata
                color: color
            };
        });

        return [{
            data: statusPercentages,
        }];
    }

    /***** Main page functions *****/
    const handleItemSelectionToggle = (event, itemId, isSelected) => {
        if (isSelected) {
            const item = rtwPublicApi.getItem(itemId);
            if (!isExpandable(item.children)) {
                /*
                    //Popup exit fullscreen
                    if (!window.confirm("Modificare la voce?")) {
                        return;
                    }
                */
                setCrudSelectedId(item.id);
                setCrudOpen(true);
            }
        }
    };

    const handleClose = () => {
        
        
        //Aggiorna qui la griglia
        setCrudOpen(false);
    };

    function updateCurrentOrder(newVal) {
        setCurrentOrder(newVal);
    }

    useEffect(() => {
		try {
            if (currentOrder) {
                Api.get({endpoint: `pcqs/list/${currentOrder.id}`, contentType : 'application/json'})
                    .then(response => {
                        setTreeViewData(response.data.pcqs);
                    })
                    .catch(error => {
                        myLog(JSON.stringify(error));
                    });
            }
		} catch (error) {
			myLog(JSON.stringify(error));
		}
    }, [currentOrder, lastUpdate]); 	// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Modal
                open={crudOpen}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <PcqCompile selectedId={crudSelectedId} handleClose={handleClose} statusIconMapping={statusIconMapping} setLastUpdate={setLastUpdate} />
            </Modal>

            <CurrentOrder updateCurrentOrder={updateCurrentOrder} />

            {treeViewData ? (
                <>
                    <RichTreeView
                        items={treeViewData}
                        slots={{ item: CustomTreeItem }}
                        sx={{ height: 'fit-content', flexGrow: 1, overflowY: 'auto' }}
                        onItemSelectionToggle={handleItemSelectionToggle}
                    />
                    <hr />
                    <h3>Analisi globale PCQ</h3>
                    <PieChart
                        series={calculateDiagramData()}
                        width={900}
                        height={300}
                    />

                </>
            ) : (
                <img className="pl-3" src={loadingGif} alt="Loading..."/>
            )}
        </>
    );
}

export default Pcq;