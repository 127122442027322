import * as Icons from '@mui/icons-material'

/* Return score in [0, 50, 100] for weak, medium, strong  */
function getPasswordStrength(passwordText) {
	let score = 0;
	/*		
		
		It must include one special symbol (: @$! % * ? &).
	*/

	//It must be at least 8char length
	if (passwordText.length < 8) {
		return score;
	}
	
	//It must include one lowercase letter
	if (passwordText !== passwordText.toLowerCase()) {
		score++;
	}
	
	//It must include one uppercase letter
	if (passwordText !== passwordText.toUpperCase()) {
		score++;
	}	
	
	//It must have one number
	if (/\d/.test(passwordText)) {
		score++;
	}	
	
	//It must have one special symbol
	if (/[^A-Za-z0-9]+/.test(passwordText)) {
		score++;
	}
	
	if (score === 4)	{
		return 100;	
	}
	
	if (score > 1) {
		return 50;	
	}
			
	return 0;	
}

function toCurrency(value) {
	let f = new Intl.NumberFormat('it-IT', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	return f.format(value);
}

function toDecimal(value) {
	let f = new Intl.NumberFormat('it-IT', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	return f.format(value);
}

function unauthorized(error) {
	return error.response.data.error.includes("unauthorized");	
}

export function myLog(event, label = " > ") {
    const now = new Date().toLocaleString();
    let prefix = "LOG [" + now + "] " + label + ": ";
    
    if (typeof event === 'string') {
        console.log(prefix + event);
    }
    else {        
        console.log(prefix + JSON.stringify(event));
    }
}

export function IconFromString(iconName) {
    const IconComponent = Icons[iconName];
    if (!IconComponent) {
        return Icons["HelpOutline"];
    }
        return IconComponent;
}

const Functions = {
    getPasswordStrength,
    toCurrency,
    toDecimal,
    unauthorized,
}

export default Functions
