import React, { useState, useEffect, useRef } from 'react';

import { useAuth } from '../../Auth/ProvideAuth';

import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Alert, Typography, Tabs, Tab } from '@mui/material';

import Api from "../../Services/Api";
import { myLog, IconFromString } from "../../Services/Functions";
import loadingGif from '../../Assets/loading.gif';

function PcqCompile(props) {
    let { selectedId: selectedPivotId, handleClose, statusIconMapping, setLastUpdate } = props;

    const inputRef = useRef(null);

    const theme = useTheme();

    let auth = useAuth();
    let role = auth.oxygenUser.roles[0];

    const [loading, setLoading] = useState(false);
    const [displayAlert, setDisplayAlert] = useState(0);
    const [apiResponse, setApiResponse] = useState(null);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [statuses, setStatuses] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [onEdit, setOnEdit] = useState(false);    
    
    const styleContent = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        overflow: 'auto',
        maxHeight: '80%',
    };

    const styleInput = {
        width: '100%',
        border: '0px',
        outline: 'none',
        color: theme.palette.primary.main,
    };

    const staticDataMapping = [
        { "label": "Macroarea", "content": "pcqtitle.area", "size": "6" },
        { "label": "Area", "content": "pcqtitle.description", "size": "6" },
        { "label": "Codice", "content": "code", "size": "6" },
        { "label": "Tipologia", "content": "tipology", "size": "6" },
        { "label": "Riferimento", "content": "reference", "size": "6" },
        { "label": "Responsabile", "content": "liable", "size": "6" },
        { "label": "Frequenza", "content": "frequency", "size": "6" },
        { "label": "Tolleranza", "content": "tolerance", "size": "6" },
        { "label": "Istruzioni operative", "content": "todo", "size": "12" },
        { "label": "Descrizione", "content": "description", "size": "12" },
    ];

    useEffect(() => {
        try {
            Api.get({endpoint: `pcqs/${selectedPivotId}`, contentType: 'application/json'})
                .then(response => {
                    setApiResponse(response.data.pcqDetail);
                    setCurrentStatus({
                       "statusId": response.data.statusId,
                       "notes": response.data.notes,
                    });
                })
                .catch(error => {
                    console.log(error);
                    setApiResponse(null);
                    setDisplayAlert(-1);
                });

            Api.get({endpoint: `pcqs/listStatuses`, contentType: 'application/json'})
                .then(response => {
                    setStatuses(response.data.pcqStatuses);
                })
                .catch(error => {
                    console.log(error);
                    setStatuses(null);
                    setDisplayAlert(-1);
                });
        } catch (error) {
            myLog(JSON.stringify(error));
            setDisplayAlert(-1);
        }
    }, []); 	// eslint-disable-line react-hooks/exhaustive-deps

	function handleCloseWithConfirmation() {
        if (onEdit && !window.confirm("Uscire senza salvare le modifiche?")) {
            return false;
        }
        handleClose();
	}

    const handleFocus = () => {
        setOnEdit(true);
    }

    const handleStatusButtonClick = (statusId) => {
        try {
            setLoading(true);

            let data = new FormData();
            let notes = inputRef.current.value;

            data.append('pcqstatus_id', statusId);
            data.append('notes', notes);
            data.append('_method', 'PUT');
            Api.post({endpoint: `pcqs/fast/${selectedPivotId}`, body: data, contentType : 'application/json'}, true)
                .then(response => {
                    setLastUpdate(Date.now());
                    setOnEdit(false);
                    setDisplayAlert(1);
                    //let timer1 = setTimeout(() => {setLoadingUpload(false);clearTimeout(timer1)}, 3000);
                })
                .catch(error => {
                    console.log(error);
                    setStatuses(null);
                    setDisplayAlert(-1);
                });
        } catch (error) {
            myLog(JSON.stringify(error));
            setDisplayAlert(-1);
        }
    };

    function InfoBox(props) {
        let {index, label, content, size} = props;

        return (
            <Grid item xs={12} sm={size} key={index}>
                <Box sx={{ border: '1px solid #ccc', borderRadius: 1, p: 2 }}>
                    <Typography variant="caption" sx={{ display: 'block', fontSize: '0.8rem', color: 'gray' }}>
                        {label}
                    </Typography>
                    <Typography variant="body1">
                        {content}
                    </Typography>
                </Box>
            </Grid>
        );
    }

    function InputBox(props) {
        let {index, label, size} = props;

        return (
            <Grid item xs={12} sm={size} key={index}>
                <Box sx={{ border: `1px solid ${theme.palette.primary.dark}`, borderRadius: 1, p: 2}}>
                    <Typography variant="caption" sx={{ display: 'block', fontSize: '0.8rem', color: 'gray' }}>
                        {label}
                    </Typography>
                    <Typography variant="body1">
                        {props.children}
                    </Typography>
                </Box>
            </Grid>
        );
    }

    function InformativeTable() {
        return (
            <Box sx={{ flexGrow: 1, p: 2 }}>
                <Grid container spacing={2}>
                    {staticDataMapping.map((item, index) => {
                        const contentPath = item.content.split('.');

                        let contentValue = apiResponse;
                        contentPath.forEach(key => {
                            contentValue = contentValue ? contentValue[key] : null;
                        });

                        return (
                            <InfoBox
                                index={index}
                                label={item.label}
                                content={contentValue || ''}
                                size={item.size}
                            />
                        );
                    })}
                </Grid>
            </Box>
        );
    }

    function CrudTable() {
        return (
            <Box sx={{ flexGrow: 1, p: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="p" style={{marginTop: '10px'}}>
                            Seleziona lo stato della PCQ
                        </Typography>
                        Tutte le attività vengono loggate - Cliccando uno dei pulsanti, vengono memorizzate anche le eventuali note qui sotto
                    </Grid>
                    <Grid item xs={12}>
                        {currentStatus && (
                            <InfoBox
                                index="notes"
                                label="Ultime note"
                                content={currentStatus.notes || ''}
                                size={12}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <InputBox
                            index='notes'
                            label='Aggiungi note'
                            size={12}
                        >
                            <input
                                ref={inputRef}
                                placeholder="Note del cambio stato (facoltativo)"
                                style={{ ...styleInput }}
                                onFocus={handleFocus}
                            />
                        </InputBox>
                    </Grid>
                    {statuses && statuses.map((button, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={React.createElement(IconFromString(statusIconMapping[button.id].icon))} // Istanzia il componente
                                disabled={loading}
                                sx={{
                                    marginBottom: 3,
                                    backgroundColor: `#${button.color}`,
                                    pointerEvents: button.id == currentStatus?.statusId ? 'none' : 'auto',
                                    fontStyle: button.id == currentStatus?.statusId ? 'italic' : 'auto',
                                    border: button.id == currentStatus?.statusId ? `2px dashed ${theme.palette.primary.main}` : '2px dashed rgba(255, 255, 255, 0)',
                                }}
                                onClick={() => handleStatusButtonClick(button.id)}
                            >
                                {`${button.label}${button.id == currentStatus?.statusId ? ' [ATTUALE]' : ''}`}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }

    function HistoryTable() {
        return (

            <h1>CIAO</h1>

        );
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    return (
        <>
            <Box sx={{ ...styleContent }}>
                <h1>Gestione PCQ</h1>

                <Tabs value={tabValue} onChange={handleTabChange} aria-label="PCQ tabs">
                    <Tab label="Dati generali" />
                    <Tab label="Storico" />
                </Tabs>

                <TabPanel value={tabValue} index={0}  style={{'minHeight': '400px'}}>
                    <InformativeTable />
                    <hr />
                    <CrudTable />
                </TabPanel>
                <TabPanel value={tabValue} index={1}  style={{'minHeight': '400px'}}>
                    <HistoryTable />
                </TabPanel>

                {loading && (
                    <div className="row styled">
                        <div className="col">
                            <>
                                <>
                                {displayAlert === 0 && (
                                    <img className="pl-3" src={loadingGif} alt="Loading..."/>
                                )}
                                </>
                                <>
                                {displayAlert === -1 && (
                                    <Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
                                )}
                                </>
                                <>
                                {displayAlert === 1 && (
                                    <Alert severity="success">
                                        <>Dati salvati con successo!</>
                                    </Alert>
                                )}
                                </>
                            </>
                        </div>
                    </div>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button variant="contained" onClick={handleCloseWithConfirmation}>Chiudi</Button>
                </Box>
            </Box>
        </>
    );
}

export default PcqCompile;