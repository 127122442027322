import React, { useState } from 'react';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';

import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, ListItemButton, ListItemIcon, ListSubheader, Dialog, DialogContent, DialogContentText, Box, CssBaseline, Badge, Popover, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { APP_NAME, APP_CLAIM, ROOT_PATH } from '../Config/constants.js';
import logoMain from '../Assets/logoMain.png';

import { mainListItems, secondaryListItems, secondaryHeader } from '../Config/mainMenu';
import AutoAvatar from "../Components/AutoAvatar";
import Copyright from "../Components/Copyright";
import FullScreen from "../Components/FullScreen";

function MobileLayout(props) {

    let {isMe, getNotificationsHook, notifications, newNotificationsNum, auth, checkRoles, handleLogout, goToHome} = props;

    const location = useLocation();
    const pathname = location.pathname;

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
    const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
    const [showFooter] = useState(true);   
    
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [overlayOpen, setOverlayOpen] = useState(false);
    
/********** CUSTOMIZE **********/
	const linkStyle = {
		textDecoration: "none",
	};

    const theme = useTheme();
/********** /CUSTOMIZE **********/

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleNotificationsClick = (event) => {
        setNotificationAnchorEl(event.currentTarget);
    };

    const handleNotificationsClose = () => {
        setNotificationAnchorEl(null);
    };

    const handleAvatarClick = (event) => {
        setAvatarAnchorEl(event.currentTarget);
    };

    const handleAvatarClose = () => {
        setAvatarAnchorEl(null);
    };

    const handleNotificationClick = (notification) => {
        setSelectedNotification(notification);
        setOverlayOpen(true);

        if (notification.displayed_at === null) {
            getNotificationsHook(notification.id);
        }
    };

    const handleOverlayClose = () => {
        setOverlayOpen(false);
        setSelectedNotification(null);
    };    

    const notificationOpen = Boolean(notificationAnchorEl);
    const notificationId = notificationOpen ? 'notification-popover' : undefined;

    const avatarOpen = Boolean(avatarAnchorEl);
    const avatarId = avatarOpen ? 'avatar-popover' : undefined;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={goToHome}
                    >
                        <img src={logoMain} alt={APP_CLAIM} title={APP_CLAIM} style={{ height: '40px', marginRight: '10px' }} />
                        <Typography variant="h6" noWrap>
                            {APP_NAME}
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                        <AutoAvatar name={auth.oxygenUser.name} sx={{ display: 'inline-block' }} onClick={handleAvatarClick} />
                    </Box>                    
                    <FullScreen desktop={false} />
                    <IconButton color="inherit" aria-label="notifications" onClick={handleNotificationsClick}>
                        <Badge badgeContent={newNotificationsNum} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Popover
                id={notificationId}
                open={notificationOpen}
                anchorEl={notificationAnchorEl}
                onClose={handleNotificationsClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List>
                    {notifications && notifications.map(notification => (
                        <ListItem button key={notification.id} onClick={() => handleNotificationClick(notification)}>
                            <ListItemText 
                                primary={notification.title} 
                                primaryTypographyProps={{                                    
                                    color: notification.displayed_at !== null ? '' : 'primary.main',
                                    fontWeight: notification.displayed_at !== null ? 'normal' : 'bold',
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Popover>
            <Popover
                id={avatarId}
                open={avatarOpen}
                anchorEl={avatarAnchorEl}
                onClose={handleAvatarClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        {auth.oxygenUser.name}
                    </Typography>
                    <Divider />
                    <List>
                        <ListItem button component="a" href={`${ROOT_PATH}/account`}>
                            <ListItemText primary="Il tuo account" />
                        </ListItem>
                        <ListItem button onClick={handleLogout}>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>
                </Box>
            </Popover>            
            <Dialog
                open={overlayOpen}
                onClose={handleOverlayClose}
                aria-labelledby="notification-dialog-title"
                aria-describedby="notification-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="notification-dialog-description">
                        {selectedNotification ? selectedNotification.message : ''}
                    </DialogContentText>
                </DialogContent>
            </Dialog>            
            <Drawer
                anchor="top"
                open={drawerOpen}
                onClose={toggleDrawer}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: theme => `rgba(${theme.palette.primary.dark.replace('#', '').match(/.{2}/g).map(hex => parseInt(hex, 16)).join(', ')}, 0.9)`,
                        color: 'white',
                    }
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: '16px',
                        paddingTop: '16px',
                    }}
                >
                    <IconButton onClick={toggleDrawer} color="inherit">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <List key="mainList">
                    {mainListItems.filter(checkRoles).map((item) => {                      
                        const active = item.path ? (pathname === item.path) : false;
                        const customStyle = {
                            pointerEvents: active ? "none" : "auto",
                            cursor: active ? "crosshair" : "pointer",
                            color: theme.palette.primary.reverseText,
                        };
                        let finalStyle = {
                            ...linkStyle,
                            ...customStyle,
                            ...(item.disabled ? { fontStyle: 'italic' } : {}),
                            ...(item.disabled ? { cursor: 'not-allowed' } : {}) // Corretto 'curso' in 'cursor' e usato 'not-allowed'
                        };
                        return (                    
                            <ListItemButton
                                component={RouterLink}
                                to={!item.disabled ? item.path : null}
                                style={finalStyle}
                                onClick={toggleDrawer}
                                disabled={active || item.disabled}
                                key={item.path}
                            >
                                <ListItemIcon style={finalStyle}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        );
                    })}
                </List>
                <ListSubheader component="div" inset>
                    {secondaryHeader}
                </ListSubheader>
                <List key="secondaryList">
                    {secondaryListItems.filter(checkRoles).map((item) => {                                 
                        const active = item.path ? (pathname === item.path) : false;
                        const customStyle = {
                            pointerEvents: active ? "none" : "auto",
                            cursor: active ? "crosshair" : "pointer",
                            color: theme.palette.primary.reverseText,
                        };
                        let finalStyle = {
                            ...linkStyle,
                            ...customStyle,
                            ...(item.disabled ? { fontStyle: 'italic' } : {}),
                            ...(item.disabled ? { cursor: 'not-allowed' } : {}) // Corretto 'curso' in 'cursor' e usato 'not-allowed'
                        };
                        return (                    
                            <ListItemButton
                                component={RouterLink}
                                to={!item.disabled ? item.path : null}
                                style={finalStyle}
                                onClick={toggleDrawer}
                                disabled={active || item.disabled}
                                key={item.path}
                            >
                                <ListItemIcon style={finalStyle}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        );
                    })}                
                </List>
            </Drawer>        
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 1 }}>
                {isMe && (
                    <div style={{ margin: '0px auto 30px auto' }}>
                        <Outlet context={isMe} />
                    </div>
                )}
            </Box>
            {showFooter &&
                <Box
                    component="footer"
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        bgcolor: theme.palette.primary.dark,
                        opacity: 0.9,
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1,
                        color: 'white'
                    }}
                >
                    <Copyright />
                </Box>
            }
        </Box>
    );
}

export default MobileLayout;
