import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function AutoAvatar (props) {
    const theme = useTheme();
    
	function stringAvatar(name: string) {
		return {
			sx: {
				bgcolor: theme.palette.secondary.readOnly,
			},
			children: name.substring(0, 1),
		};
	}
	
	return (
		<Avatar {...stringAvatar(props.name)} onClick={props.onClick} />
	);
}

export default AutoAvatar;
