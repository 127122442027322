import { Link, Outlet } from 'react-router-dom';
import { useAuth } from './Auth/ProvideAuth'

import './app.css';
import logoMain from './Assets/logoMain.png';
import { APP_NAME, ROOT_PATH } from './Config/constants.js';

function App() {
	let auth = useAuth();

	return (
		<>
			<div className="welcome">
				<div className="logo">					
					{!auth.oxygenUser ? (
						<Link to={`login`}>
							<img src={logoMain} alt={APP_NAME} title={APP_NAME} />
						</Link>
					) : (
						<Link to={ROOT_PATH}>
							<img src={logoMain} alt={APP_NAME} title={APP_NAME} />
						</Link>
					)}
				</div>
			</div>
			<Outlet />
		</>
	)

}

export default App;
