export const DEV = process.env.REACT_APP_DEV === "true" ? true : false
export const APP_NAME = process.env.REACT_APP_APP_NAME
export const APP_CLAIM = process.env.REACT_APP_APP_CLAIM
export const VERSION = process.env.REACT_APP_VERSION
export const MAIN_SITE = process.env.REACT_APP_MAIN_SITE
export const API_URL = process.env.REACT_APP_API_URL
export const NODE_ENV = process.env.NODE_ENV	//development | production

export const ADMIN_ROLE = 'administrator'
export const BACKOFFICE_ROLE = 'backoffice'
export const OPERATOR_ROLE = 'operator'

export const PAGE_SIZE = 10
export const ROOT_PATH = "/cms";
export const LOGIN_PATH = "/login";

export const pageSizeOptions = [
	5,
	10,
	20,
	40,
	50
];


