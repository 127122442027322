import React, { useState } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';

/* MUI ELEMENTS */
import { styled, useTheme } from '@mui/material/styles';

import { CssBaseline, Box, Toolbar, IconButton, Typography, Badge, Divider, List, ListItem, Container, MenuItem, MenuList, Dialog, DialogContent, DialogContentText} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { Tooltip, Popover } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

/* SERVICE */
import {ROOT_PATH, APP_NAME} from '../Config/constants.js';
import Copyright from "../Components/Copyright";
import AutoAvatar from "../Components/AutoAvatar";
import FullScreen from "../Components/FullScreen";
import { mainListItems, secondaryListItems, secondaryHeader } from '../Config/mainMenu';

import logoSmall from '../Assets/logoSmall.png'

//Inspired by https://mui.com/material-ui/getting-started/templates/ "Dashboard"
function CmsLayout(props) {

    let {isMe, getNotificationsHook, notifications, newNotificationsNum, auth, checkRoles, handleLogout, goToHome} = props;

	const location = useLocation();
	const pathname = location.pathname;

	const [open, setOpen] = useState(true);
	const [showFooter] = useState(true);

	const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorElNotification, setAnchorElNotification] = useState(null);

    const [selectedNotification, setSelectedNotification] = useState(null);
    const [overlayOpen, setOverlayOpen] = useState(false);

	const handleClickUser = (event) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleCloseUser = () => {
		setAnchorElUser(null);
	};
	const openUserPopover = Boolean(anchorElUser);
	const idUserPopover = openUserPopover ? 'simple-popover' : undefined;

    const handleClickNotifications = (event) => {
        setAnchorElNotification(event.currentTarget);
    };
    const handleCloseNotifications = () => {
        setAnchorElNotification(null);
    };
    const openNotificationsPopover = Boolean(anchorElNotification);
    const idNotificationsPopover = openNotificationsPopover ? 'simple-popover' : undefined;

    const handleNotificationClick = (notification) => {
        setSelectedNotification(notification);
        setOverlayOpen(true);

        if (notification.displayed_at === null) {
            getNotificationsHook(notification.id);
        }
    };

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const drawerWidth = 240;

    const handleOverlayClose = () => {
        setOverlayOpen(false);
        setSelectedNotification(null);
    };

	const AppBar = styled(MuiAppBar, {
			shouldForwardProp: (prop) => prop !== 'open',
		})(({ theme, open }) => ({
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}));

	const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
		({ theme, open }) => ({
			'& .MuiDrawer-paper': {
				position: 'relative',
				whiteSpace: 'nowrap',
				width: drawerWidth,
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				}),
				boxSizing: 'border-box',
				...(!open && {
					overflowX: 'hidden',
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					width: theme.spacing(7),
					[theme.breakpoints.up('sm')]: {
						width: theme.spacing(9),
					},
				}),
			},
		}),
	);

/********** CUSTOMIZE **********/
	const linkStyle = {
		textDecoration: "none",
	};

    const theme = useTheme();
/********** /CUSTOMIZE **********/

	return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open} color="primary">
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {APP_NAME}
                    </Typography>
                    
                    <div>
                        <FullScreen desktop={true} />
                        <Tooltip title="Apri notifiche">
                            <IconButton onClick={handleClickNotifications} color="inherit" sx={{ pr: 3 }}>
                                <Badge badgeContent={newNotificationsNum} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Popover
                            id={idNotificationsPopover}
                            open={openNotificationsPopover}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handleCloseNotifications}
                        >
                            <List>
                                {notifications && notifications.map(notification => (
                                    <ListItem button key={notification.id} onClick={() => handleNotificationClick(notification)}>
                                        <ListItemText
                                            primary={notification.title}
                                            primaryTypographyProps={{
                                                color: notification.displayed_at !== null ? '' : 'primary.main',
                                                fontWeight: notification.displayed_at !== null ? 'normal' : 'bold',
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Popover>
                    </div>
                    <div>
                        <Tooltip title="Apri account">
                            <IconButton onClick={handleClickUser} sx={{ p: 0 }} >
                                <AutoAvatar name={auth.oxygenUser.name} />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            id={idUserPopover}
                            open={openUserPopover}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handleCloseUser}
                        >
                            <Box
                                sx={{
                                    py: 1.5,
                                    px: 2
                                }}
                            >
                                <Typography variant="overline">
                                    Account
                                </Typography>
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Ciao {auth.oxygenUser.name}
                                </Typography>
                            </Box>
                            <Divider />
                            <MenuList
                                disablePadding
                                dense
                                sx={{
                                    p: '8px',
                                    '& > *': {
                                        borderRadius: 1
                                    }
                                }}
                            >
                                <MenuItem
                                    onClick={handleCloseUser}
                                    component={Link}
                                    to={`${ROOT_PATH}/account`}
                                >
                                    Il tuo account
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    Logout
                                </MenuItem>
                            </MenuList>
                        </Popover>
                    </div>
                </Toolbar>
            </AppBar>
            <Dialog
                open={overlayOpen}
                onClose={handleOverlayClose}
                aria-labelledby="notification-dialog-title"
                aria-describedby="notification-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="notification-dialog-description">
                        {selectedNotification ? selectedNotification.message : ''}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                    }}
                >
                    <Link to={`#`} onClick={goToHome} style={{margin: 'auto'}}>
                        <img src={logoSmall} alt={APP_NAME}/>
                    </Link>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    {mainListItems.filter(checkRoles).map((item) => {
                        const active = item.path ? (pathname === item.path) : false;
                        const customStyle = {
                            pointerEvents: active ? "none" : "default",
                            cursor: active ? "crosshair" : "default",
                            color: theme.palette.primary.dark,
                        };
                        let finalStyle = {
                            ...linkStyle,
                            ...customStyle,
                            ...(item.disabled ? { fontStyle: 'italic' } : {}),
                            ...(item.disabled ? { cursor: 'not-allowed' } : {}) // Corretto 'curso' in 'cursor' e usato 'not-allowed'
                        };

                        return (
                            <React.Fragment
                                key={`${item.path}_rf`}
                            >
                                <Link
                                    to={!item.disabled ? item.path : null}
                                    style={finalStyle}
                                >
                                    <ListItemButton
                                        disabled={active || item.disabled}
                                    >
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </Link>
                            </React.Fragment>
                        );
                    })}
                    <Divider sx={{ my: 1 }} />
                    <ListSubheader component="div" inset>
                        {secondaryHeader}
                    </ListSubheader>
                    {secondaryListItems.filter(checkRoles).map((item) => {
                        const active = item.path ? (pathname === item.path) : false;
                        const customStyle = {
                            pointerEvents: active ? "none" : "default",
                            cursor: active ? "crosshair" : "default",
                            color: theme.palette.primary.dark,
                        };
                        let finalStyle = {
                            ...linkStyle,
                            ...customStyle,
                            ...(item.disabled ? { fontStyle: 'italic' } : {}),
                            ...(item.disabled ? { cursor: 'not-allowed' } : {}) // Corretto 'curso' in 'cursor' e usato 'not-allowed'
                        };

                        return (
                            <React.Fragment
                                key={`${item.path}_rf`}
                            >
                                <Link
                                    to={!item.disabled ? item.path : null}
                                    style={finalStyle}
                                >
                                    <ListItemButton
                                        disabled={active || item.disabled}
                                    >
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </Link>
                            </React.Fragment>
                        );
                    })}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    paddingBottom: '60px'
                }}
            >
                <Toolbar />
                <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                    {isMe && (
                        <Outlet context={isMe} />
                    )}
                </Container>
            </Box>
            {showFooter && (
                <AppBar position="absolute" open={open} color="primary">
                    <Box
                        component="footer"
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            width: '100%',
                            bgcolor: theme.palette.primary.dark,
                            opacity: 0.9,
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 1,
                            color: 'white'
                        }}
                    >
                        <Copyright desktop={true} />
                    </Box>
                </AppBar>
            )}
        </Box>
	);
}

export default CmsLayout;
