import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';

import Api from "../Services/Api";
import { myLog } from "../Services/Functions";

function CurrentOrder(props) {
    const theme = useTheme();

    const [currentOrder, setCurrentOrder] = useState(null);
    const [isVisible, setIsVisible] = useState(true); // Stato per gestire la visibilità

    // Stile del box modificato
    const boxStyle = {
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
        padding: '10px',
        borderRadius: '5px', // Spigoli arrotondati leggermente
        margin: '2px 0',     // Margine di 2px sopra e sotto
        textAlign: 'left',    // Testo allineato a sinistra
        position: 'relative', // Posizione relativa per posizionare la "X"
    };

    // Stile del bottone di chiusura "X" modificato
    const closeButtonStyle = {
        position: 'absolute',
        top: '-5px', // Leggermente fuori dal bordo superiore
        right: '-5px', // Leggermente fuori dal bordo destro
        backgroundColor: 'white', // Sfondo bianco per la X
        color: theme.palette.primary.main, // Colore "main" per la X
        border: `2px solid ${theme.palette.primary.main}`, // Bordo "main"
        borderRadius: '50%',
        width: '25px',
        height: '25px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    useEffect(() => {
        try {
            Api.get({ endpoint: 'manage/currentOrder', contentType: 'application/json' })
                .then(response => {
                    setCurrentOrder(response.data.currentOrder);
                    if (props.updateCurrentOrder) {
                        props.updateCurrentOrder(response.data.currentOrder);
                    }
                })
                .catch(error => {
                    myLog(JSON.stringify(error));
                    setCurrentOrder("Impossibile caricare la commessa attiva...");
                });
        } catch (error) {
            myLog(JSON.stringify(error));
            setCurrentOrder("Impossibile caricare la commessa attiva...");
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!isVisible) return null; // Se non è visibile, non renderizzare nulla

    return (
        <div style={boxStyle}>
            <button style={closeButtonStyle} onClick={() => setIsVisible(false)}>
                <strong>X</strong>
            </button>
            <p style={{'marginBottom': '0px'}}>Stai lavorando su: {currentOrder ? `Commessa ${currentOrder.order} - ${currentOrder.customer} [${currentOrder.worksite}]` : "In caricamento..."}</p>
        </div>
    );
}

export default CurrentOrder;
