import React, { useState, useEffect } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { IconButton, Tooltip } from '@mui/material';

function FullScreen (props) {
    const [onFullScreen, setOnFullScreen] = useState(false);
    
    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);
    
    const handleFullscreenChange = () => {
        setOnFullScreen(!!document.fullscreenElement);
    };
    
    function handleFullscreen() {
        const elem = document.documentElement;

        if (!onFullScreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { // Firefox
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, Opera
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { // IE/Edge
                elem.msRequestFullscreen();
            }
        }
        else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
        }
    }
    
    if (props.login || props.desktop) { 
        return (
            <Tooltip title={`${onFullScreen ? 'Chiudi ' : ''}Full screen`}>
                <IconButton onClick={() => { handleFullscreen(); setOnFullScreen(!onFullScreen); }} color="inherit" sx={{ pr: 1 }}>
                    {onFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
            </Tooltip>    
        )
    }
    else {
        return (
            <IconButton color="inherit" aria-label="fullScreen" onClick={() => { handleFullscreen(); setOnFullScreen(!onFullScreen); }}>
                {onFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
        )
    }
}

export default FullScreen;
