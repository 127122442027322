import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { Button, Typography, Badge  } from '@mui/material';

function MainButton(props) {
    let { badge, label, icon: Icon, buttonColor, loading, onClick, href } = props;
    
	const theme = useTheme();

    const buttonStyle = {
        width: '200px',
        height: '200px',
        borderRadius: '16px',
        padding: '20px',
        textTransform: 'none',
        fontSize: '18px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: buttonColor
    };
    
	const linkStyle = {
		textDecoration: "none",
	};    
    
    const buttonElement = (onClickEvent) => {
        return (
            <Button variant="contained" color="primary" sx={buttonStyle} disabled={loading} onClick={onClickEvent}>
                <Icon sx={{ fontSize: '40px', marginBottom: '8px' }} />
                <Typography variant="h6">{label}</Typography>
            </Button>
        );
    };
    
    const linkedButtonElement = () => {
        if (onClick) {
            return (
                <>
                    {buttonElement(onClick)}
                </>
            );
        }
        if (href) {
            return (
                <Link
                    to={href}
                    style={linkStyle}
                >
                    {buttonElement()}
                </Link>
            );
        }
        
        return buttonElement();
    };
        
    return (
        <>
            {badge > 0 ? (
                <Badge
                    badgeContent={badge}
                    color="secondary"
                    sx={{
                        '& .MuiBadge-badge': {
                            backgroundColor: theme.palette.secondary.readOnly,
                            color: theme.palette.primary.main,
                            transform: 'scale(3)',
                            top: '-15px',
                            right: '-15px',
                        },
                    }}
                >
                    {linkedButtonElement()}
                </Badge>
            ) : (
                linkedButtonElement()
            )}
        </>
    );
}

export default MainButton;
