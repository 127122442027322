function FormikValidation (props) {
	let errors = props.formik.errors;
	
	return (
		<>
			{Object.keys(errors).length > 0 && (
				props.tech ? (
                    <p style={{'fontSize': '0.6em', 'color': 'gray'}}>Dati tecnici: {JSON.stringify(errors)}</p>
				) : (
					props.displayValidation && (<p style={{'color': 'red', 'marginBottom': '0px', 'marginTop': '1em'}}>Ricontrolla gli errori indicati nei vari campi</p>)
				)
			)
			}			
		</>
	);
}

export default FormikValidation;
