import { useState, useEffect} from 'react';
import { useOutletContext } from "react-router-dom";
import { useAuth } from '../Auth/ProvideAuth'

import { Card, CardContent, CardHeader, Button, InputAdornment, IconButton, Alert } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import {ADMIN_ROLE, BACKOFFICE_ROLE} from '../Config/constants.js';
import loadingGif from '../Assets/loading.gif';
import PasswordStrength from "../Components/PasswordStrength";
import Api from "../Services/Api";
import Functions from "../Services/Functions";
import FastTextField from "../Components/FastTextField";
import FormikValidation from "../Components/FormikValidation";

function Account() {
	const me = useOutletContext();
	const [apiResponse] = useState(me)
	const [configSms, setConfigSms] = useState(null);
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	let auth = useAuth();

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	}
	const handleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	}

	useEffect(() => {
		try {
			if(auth.oxygenUser.roles[0].name === ADMIN_ROLE || auth.oxygenUser.roles[0].name === BACKOFFICE_ROLE) {
				Api.get({endpoint: `config/smsStatus`, contentType : 'application/json'})
				.then(response => {
					setConfigSms(response.data.smsStatus)
				})
				.catch(error => {
					console.log(error);
					setConfigSms(null);
				});
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	const formik = useFormik({
		initialValues: {
			name: apiResponse?.name ?? "",
			surname: apiResponse?.surname ?? "",
			birth_date: apiResponse?.birth_date ? moment(apiResponse?.birth_date).format('YYYY-MM-DD') : "",
			email: apiResponse?.email ?? "",
			phone_number: apiResponse?.phone_number ?? "",
			password: '',
			password_confirmation: '',
			_method: 'put',
		},
		validate : values => {
            let errors = {}
            if(values.password && Functions.getPasswordStrength(values.password) < 50 ){
                errors.password = "La password non rispetta i criteri di complessità"
            }
            return errors
        },
		validationSchema: Yup.object({
			name:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			surname:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			birth_date:
				Yup.date(),
			phone_number:
				Yup.string()
				.matches(/^\d{9,10}$/, 'Formato non valido'),
			password:
				Yup.string(),
			password_confirmation:
				Yup.string()
				.oneOf([Yup.ref('password')], 'Le password non corrispondono'),
		}),
		onSubmit: values => {
			setLoading(true);

			try {
				Api.post({endpoint: 'me', body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						//let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	return (
		<>
			<form
				autoComplete="off"
				noValidate
				onSubmit={formik.handleSubmit}
			>
				<Card>
					<CardHeader
						title="Il tuo account"
						subheader="Visualizza e modifica i dati del tuo account"
					/>
					<CardContent sx={{ pt: 0 }}>
						<div className="container">
							<div className="row styled">
								<div className="col-md-6">
									<FastTextField required loading={loading} formik={formik} label="Cognome" field="surname"/>
								</div>
								<div className="col-md-6">
									<FastTextField required loading={loading} formik={formik} label="Nome" field="name"/>
								</div>
							</div>
							<div className="row styled">
								<div className="col-md-6">
									<FastTextField required loading={true} formik={formik} label="Email" field="email"/>
								</div>
								<div className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Telefono" field="phone_number"/>
								</div>
							</div>
							<div className="row styled">
								<div className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Data di nascita" field="birth_date" type="date"/>
								</div>
								<div className="col-md-6" style={{fontSize: '0.7em'}}>
									Per modificare la password compilare i campi [Password] e [Conferma Password]<br />Altrimenti la password resterà invariata
								</div>
							</div>
							<div className="row styled">
								<div className="col-md-6">
									<FastTextField
										loading={loading} formik={formik} label="Password" field="password"
										type={showPassword?"text":"password"}
										autoComplete="off"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleShowPassword}
														edge="end"
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<div>
										<PasswordStrength password={formik.getFieldProps('password').value} />
									</div>
								</div>
								<div className="col-md-6">
									<FastTextField
										loading={loading} formik={formik} label="Conferma Password" field="password_confirmation"
										type={showPassword?"text":"password"}
										autoComplete="off"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleShowConfirmPassword}
														edge="end"
													>
														{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</div>
							</div>
							<div className="row styled">
								<div className="col">
									{loading && (
											<>
												<>
												{displayAlert === 0 && (
													<img className="pl-3" src={loadingGif} alt="Loading..."/>
												)}
												</>
												<>
												{displayAlert === -1 && (
													<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
												)}
												</>
												<>
												{displayAlert === 1 && (
													<Alert severity="success">Dati modificati con successo!</Alert>
												)}
												</>
											</>
									)}
									{!loading && (
										<Button
											variant="contained"
											type="submit"
										>
											Modifica dati
										</Button>
									)}
								</div>
							</div>
						</div> {/* container */}
						<FormikValidation formik={formik} />
					</CardContent>
				</Card>
				<FormikValidation formik={formik} tech={true} />
			</form>
			{configSms && (
				<Card sx={{ mt: 2}}>
					<CardHeader
						title="Gestione SMS"
						subheader="Dettagli del sistema di invio SMS"
					/>
					<CardContent sx={{ pt: 0 }}>
						<div className="container">
							<p><strong>Sistema: </strong>{configSms.enabled ? "ATTIVO" : "NON ATTIVO"}</p>
							<p><strong>Numero di SMS rimanenti nel piano sottoscritto: </strong>{configSms.remaining_sms} (Aggiornato all'ultimo invio)</p>
						</div>
					</CardContent>
				</Card>
			)}
		</>
	);
}

export default Account;
