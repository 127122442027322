import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { BrowserView, MobileView, isIE } from 'react-device-detect';

import { useAuth } from '../Auth/ProvideAuth'

import CmsLayout from './CmsLayout';
import MobileLayout from './MobileLayout';
import Api from "../Services/Api";

import { ADMIN_ROLE, ROOT_PATH } from '../Config/constants.js';

function LayoutSelector() {

    let auth = useAuth();
    
    let navigate = useNavigate();

    const location = useLocation();
    const pathname = location.pathname;
    
    const [isMe, setIsMe] = useState(null);
    const [notifications, setNotifications] = useState(null);
    const [newNotificationsNum, setNewNotificationsNum] = useState(0);
	const getMe = () => {
		try {
			Api.get({endpoint: `me`, contentType : 'application/json'})
			.then(response => {
				setIsMe(response.data);                                
			})
			.catch(error => {
				navigate("/login");
			});
		} catch (error) {
			console.log(error);
			navigate("/login");
		}	
	}

    const getNotifications = () => {
		try {
			Api.get({endpoint: `notifications`, contentType : 'application/json'})
			.then(response => {
                let notifications = response.data.notifications;
				setNotifications(notifications);
                const initialNewNotificationsCount = notifications.filter(notification => notification.displayed_at === null).length;
                setNewNotificationsNum(initialNewNotificationsCount);                
			})
			.catch(error => {
				setNotifications(null);
                setNewNotificationsNum(0);
			});
		} catch (error) {
			console.log(error);
			setNotifications(null);
            setNewNotificationsNum(0);
		}	
	}
    
	useEffect(() => {
		getMe();
		getNotifications();
	}, [pathname]);	// eslint-disable-line react-hooks/exhaustive-deps
    
    function getNotificationsHook(notificationId) {
		try {
            Api.post({endpoint: 'notifications', body: { 'notificationId': Number(notificationId)}, contentType : 'application/json'})            
			.then(response => {
                getNotifications();
			})
			.catch(error => {
                if (error.response.data.code === 422) {
                    console.log("Already readed");
                    getNotifications();    
                }
                else {
                    console.log(error);
                }
			});
		} catch (error) {
			console.log(error);
		}	         
    }
    
    
	function handleLogout(e) {
		if (!window.confirm("Confermare il logout?"))
			return;
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		auth.signout();
		navigate("/");
	}

	function checkRoles(value) {
		if(auth.oxygenUser.roles[0].name === ADMIN_ROLE || !value.roles || (value.roles && value.roles.includes(auth.oxygenUser.roles[0].name))) {
			return true;
		}
		else {
			return false;
		}
	}
    
    const goToHome = (e) => {
        if (e) {
            e.preventDefault();
        }
        navigate(ROOT_PATH);
    };    
    
    return (
        <>            
            <MobileView>
                <MobileLayout isMe={isMe} getNotificationsHook={getNotificationsHook} notifications={notifications} newNotificationsNum={newNotificationsNum} auth={auth} checkRoles={checkRoles} handleLogout={handleLogout} goToHome={goToHome} />
            </MobileView>
            
            <BrowserView>
                {isIE && <div> IE is not supported. Download Chrome/Opera/Firefox </div>}
                <CmsLayout isMe={isMe} getNotificationsHook={getNotificationsHook} notifications={notifications} newNotificationsNum={newNotificationsNum} auth={auth} checkRoles={checkRoles} handleLogout={handleLogout} goToHome={goToHome} />
            </BrowserView>
        </>
    )
}

export default LayoutSelector;
