import {APP_NAME, VERSION} from '../Config/constants.js';
import {Typography} from '@mui/material';

function Copyright (props) {
    if (props.login || props.desktop) { 
        return (
        <Typography variant="body2">
            Version {VERSION} - &copy; {new Date().getFullYear()} {APP_NAME} - All rights reserved
        </Typography>
        )
    }
    else {
        return (
            <>
                <Typography variant="body2">
                    Version {VERSION}
                </Typography>    
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} {APP_NAME} - All rights reserved
                </Typography>  
            </>
        )
    }
}

export default Copyright;
