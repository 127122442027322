import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { ROOT_PATH } from '../Config/constants.js';


//Can add filter by role
/*
  {
    title: 'Appuntamenti',
    path: basePath+'/appointments',
    icon: (
		<DateRangeIcon />
    ),
	roles: [
		'backoffice'
	]	
  },
*/
export const mainListItems = [
    {
        title: 'Dashboard',
        path: ROOT_PATH,
        icon: (
            <DashboardIcon />
        ),
    }, 
    {
        title: 'PCQ',
        path: ROOT_PATH + "/pcq",
        icon: (
            <ChecklistIcon />
        ),
    }, 
    {
        title: 'Rapportini',
        path: ROOT_PATH + "/reports",
        icon: (
            <AssignmentIcon />
        ),
        disabled: true
    },       
];

export const secondaryHeader = "Impostazioni";

export const secondaryListItems = [
    {
        title: 'Il tuo account',
        path: ROOT_PATH + "/account",
        icon: (
            <PersonIcon />
        ),
    },
];
