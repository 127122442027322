import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Typography, Button, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material/styles';

import { ROOT_PATH } from '../Config/constants.js';

function NotFound () {
    const navigate = useNavigate();
    const theme = useTheme();
    
    const handleGoHome = () => {
        navigate(ROOT_PATH);
    };
    
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                bgcolor: 'background.default',
                color: theme.palette.primary.main,
            }}
        >
            <ErrorOutlineIcon sx={{ fontSize: 100, color: 'error.main' }} />
            <Typography variant="h1" component="h1" gutterBottom>
                404
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
                Oops! Pagina non trovata.
            </Typography>
            <Typography variant="body1" gutterBottom>
                La pagina che stai cercando non esiste o è stata spostata
            </Typography>
            <Box mt={4}>
                <Button variant="contained" onClick={handleGoHome}>
                    Home page
                </Button>
            </Box>
        </Container>
    );
    
    
}

export default NotFound;
