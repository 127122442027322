import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes ,Route } from 'react-router-dom';

import './global.css';

import { ProvideAuth } from './Auth/ProvideAuth'
import App from './App';

import Dashboard from './Pages/Dashboard';
import Account from './Pages/Account';
import Pcq from './Pages/Pcq';

import RouteManager from './RouteManager';
import NotFound from './Pages/NotFound';
import Login from './Pages/Login';
import LayoutSelector from './Layout/LayoutSelector';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'font-awesome/css/font-awesome.min.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { indigo, blue } from '@mui/material/colors';

const root = ReactDOM.createRoot(document.getElementById('root'));

const customTheme = createTheme({
    palette: {
        primary: {
            main:           indigo[500],
            readOnly:       indigo[200],
            dark:           indigo[900],
            reverseText:    'white',
        },
        secondary: {
            main:           blue[500],
            readOnly:       blue[200],
            dark:           blue[900],
            reverseText:    'white',
        }
    },
});
    
root.render(
	<React.StrictMode>
		<ProvideAuth>
            <ThemeProvider theme={customTheme}>	{/* or defaultTheme */}
                <BrowserRouter >
                    <Routes>
                        <Route exact path="/login" element={<Login />} />   {/* mapped in constants.js */}
                        <Route exact path="/" element={<App />} />
                        <Route exact path="/cms" element={<RouteManager component={LayoutSelector} />}>   {/* url mapped in constants.js */}
                            <Route exact path="/cms" element={<RouteManager component={Dashboard} />} />	{/* Main content https://localhost:3000/cms */}
                            <Route exact path="account" element={<RouteManager component={Account} />} />
                            <Route exact path="pcq" element={<RouteManager component={Pcq} />} />
                            
                            
                            {/* <Route exact path="timetable" element={<RouteManager component={Timetable} role="backoffice" />} /> */}
                            
                        </Route>
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </BrowserRouter >
            </ThemeProvider>
		</ProvideAuth>
	</React.StrictMode>
);
